.back-img{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0%);
}
.back-color{
    width: 100%;
    height: 100%;
    background-color: rgb(237,217,244);
}
.github-log{
    position: absolute;
    right: 12px;
    top: 12px;
    img{
        width: 36px;
        height: 36px;
        cursor: pointer;
        margin: 0 12px;
    }
}