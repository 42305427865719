.luy-out{
    width: 100%;
    height: 100%;
}
#satellite{
    width: 100%;
    height: 100%;
}
.tool{
    position: absolute;
    left: 24px;
    top: 24px;
}
.start{
    width: 48px;
    height: 48px;
    background-color: aquamarine;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}