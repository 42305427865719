.luy-out{
    width: 100%;
    height: 100%;
    display: flex;
}
.left-content{
    height: 100%;
    flex: 1;
}
.header-tool{
    position: relative;
    height: 30px;
    background-color: #265ecd;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 12px;
}
#container{
    height: 100%;
    width: 100%;
}
.view-content{
    height: 100%;
    width: 70%;
    // background-color: red;
}
.start-btn{
    padding: 2px 8px;
    background-color: aquamarine;
    border-radius: 2px;
    font-size: 10px;
    cursor: pointer;
}
iframe{
    width: 100%;
    height: 100%;
}