.luy-out{
    width: 100%;
    height: 100%;
}
#intro{
    width: 100%;
    height: 100%;
    canvas{
        width: 100%;
        height: 100%;
        border-style: unset;
        display: block
    }
}

.mask-load{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,1);
}